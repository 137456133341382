import { ContextRequest } from './context-request';
import { ErrorMessageObject, ErrorResponse } from './error';
import { PaginationRequest } from './pagination-request';
import { ItemType } from './utils/enums';
import {PublicProfile, ShortPublicProfile, UserProfileImage} from './public-profile';
import { ApplaudsSuccessResponseBase, UsersThatApplauded } from './applaud';

export type PaginationContextRequestParams = PaginationRequest & ContextRequest;

export type ArticleProvider = 'ovid' | 'pdf' | 'sicenceDirect';

interface ArticleBase {
  _id: string;
}

interface OrcIdArticleBase {
  _id?: string;
}

interface ArticleCommon {
  authors?: Array<string>;
  channel?: string;
  content?: string;
  date?: string;
  hasAccessToResource: boolean; // determine if logged in user has access to this resource (feature access).
  hasFullText?: boolean;
  hasRecommended?: boolean;
  hasBookmarked?: boolean;
  hidden: boolean; // used for publish mode
  interests?: Array<string>; // aggregate interestId and interests array.
  isPictureOnlyContent?: boolean;
  journal: string;
  meta?: meta; // aggregate with field name metadata on backend. Define meta/metadata object as interface
  origin?: string;
  previewImageUrl?: string;
  published: boolean; // use for publish mode
  recommends?: Array<UserProfileImage>;
  title: string;
  totalNumberOfRecommends?: number;
  widgetId?: string;
  id?: string;
  excerpt?: string;
  isFullMemberAccess?: boolean;
  provider?: ArticleProvider;
  myaoAuthorsCount?: number;
  showAoDisclaimer?: boolean;
}

export interface Article extends ArticleBase, ArticleCommon {
  type: ItemType.article;
  myAOAuthors?: Array<ShortPublicProfile>;
}

export interface Video extends ArticleBase, ArticleCommon {
  type: ItemType.video;
  hasTranscript?: boolean;
}

export interface AOVideo extends ArticleBase, ArticleCommon {
  type: ItemType.aoVideo;
  hasTranscript?: boolean;
}

export interface OrcIdArticle extends OrcIdArticleBase, ArticleCommon {
  myAOAuthors?: Array<ShortPublicProfile>;
  type: ItemType.orcid;
  hasLiked?: boolean;
  usersThatApplauded?: Array<UsersThatApplauded>;
  numberOfLikes?: number;
}

export interface meta {
  Video?: string;
  Consultants?: string;
  Language?: string;
  LearningObjectives?: string;
  AOVideoRef_ID?: string;
  MyAO_Interests?: string;
}

export interface ArticlesRequestParams extends ContextRequest {
  hidden?: boolean;
  published?: boolean;
  interestId?: string;
  channelId?: string;
}

export interface ArticleCardsByIdRequest {
  _schemaPath?: 'backend/ionic/articles/get/<articleId>,<articleId>';
  _schemaMethod?: 'get';
  _schemaGetParams?: ArticlesRequestParams;
}

// TODO - replace this usage by ArticleCardsByIdRequest in clients
export interface ArticlesRequest {
  _schemaPath?: 'backend/ionic/articles/get/<articleId>,<articleId>';
  _schemaMethod?: 'get';
  _schemaGetParams?: ArticlesRequestParams;
}
export interface ArticleCardsRequest {
  _schemaPath?: 'backend/ionic/articles/';
  _schemaMethod?: 'get';
  _schemaGetParams?: ArticlesRequestParams;
}

export type GetArticlesByCampaignIdParams = PaginationRequest & ContextRequest;

export interface GetArticlesByCampaignIdRequest {
  _schemaPath?: 'backend/ionic/articles/by/campaign/<campaignId>';
  _schemaMethod?: 'get';
  _schemaGetParams?: ArticlesRequestParams;
}

export interface GetArticlesByCampaignIdResponse {
  _schemaPath?: 'backend/ionic/articles/by/campaign/<campaignId>';
  _schemaMethod?: 'get';
  channelConfig?: any;
  result: Array<Article>;
}

export interface UpdateArticleRequest {
  _schemaPath?: 'backend/ionic/articles/by/channel/:channelId';
  _schemaMethod?: 'put';
  _schemaGetParams?: ArticlesRequestParams;
}

export interface ArticlesSuccessResponse {
  _schemaPath?: 'backend/ionic/articles';
  _schemaMethod?: 'get';
  result: Array<Article | Video | AOVideo>;
}

export type ArticlesResponse = ArticlesSuccessResponse | ErrorResponse;

export interface ArticlesForPublishingParams {
  appId: string;
  publisher: boolean;
  published: boolean;
  hidden: boolean;
  page: number;
  count: number;
}

export interface ArticlesPublishResponse {
  article: string;
  _schemaPath?: 'backend/ionic/articles';
  _schemaMethod?: 'post';
}

export type GetArticleRecommendsRequestParams = PaginationRequest &
  ContextRequest;

export interface GetArticleRecommendsSuccessResponse {
  _schemaPath?: 'backend/ionic/articles/recommends';
  _schemaMethod?: 'get';
  result: Array<ShortPublicProfile>;
  totalItemsCount: number;
}

export type GetArticleRecommendsResponse =
  | GetArticleRecommendsSuccessResponse
  | ErrorResponse;

export interface ArticleFulltextInfo {
  provider: 'direct' | 'ao';
  url: string;
}

export interface GetArticleFulltextLinkResponse {
  _schemaPath?: 'backend/ionic/articles/fulltext/<articleId>';
  _schemaMethod?: 'get';
  success: boolean;
  result: ArticleFulltextInfo;
}

export type GetVideosRequestParams = PaginationRequest & ContextRequest & {query?: string};

export interface GetVideosSuccessResponse {
  _schemaPath?: 'backend/ionic/videos';
  _schemaMethod?: 'get';
  result: Array<Video>;
  totalItemsCount: number;
}

export type GetVideosResponse = GetVideosSuccessResponse | ErrorResponse;

export type VideoTranscriptSummary = Array<string>;

export interface GetVideoTranscriptSummarySuccessResponse {
  result?: VideoTranscriptSummary;
}

export type GetVideoTranscriptSummaryResponse =
  GetVideoTranscriptSummarySuccessResponse & ErrorMessageObject;

export type GetOrcIdArticlesParams = PaginationRequest & ContextRequest;

export interface GetOrcIdArticlesSuccessResponse {
  _schemaPath?: 'backend/ionic/articles/orcid';
  _schemaMethod?: 'get';
  result: Array<OrcIdArticle>;
  totalItemsCount: number;
}

export interface GetPublishedOrcIdArticlesSuccessResponse {
  _schemaPath?: 'backend/ionic/articles/orcid/published';
  _schemaMethod?: 'get';
  result: {author: PublicProfile, article: OrcIdArticle}[];
  success: boolean;
  totalItemsCount: number;
}

export type GetPublishedOrcIdArticlesResponse =
  | GetPublishedOrcIdArticlesSuccessResponse
  | ErrorResponse;

export type GetOrcIdArticlesResponse =
  | GetOrcIdArticlesSuccessResponse
  | ErrorResponse;

export interface GetOrcIdArticleLikesSuccessResponse extends ApplaudsSuccessResponseBase {
  _schemaPath?: '/backend/ionic/orcid/[publication ID]/like';
  _schemaMethod?: 'get';
  _schemaGetParams?: PaginationContextRequestParams;
}

export type GetOrcIdArticleLikesResponse = GetOrcIdArticleLikesSuccessResponse | ErrorResponse;

export enum ArticleTagType {
  interests = 'interests',
  journals = 'journals',
  channels = 'channels',
  language = 'language',
  fullText = 'full-text'
}

export type ArticleType = Article | Video | AOVideo;
